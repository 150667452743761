@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

$buttonColor: (
  error: var(--color-error),
  warn: var(--color-warn),
  success: var(--color-success),
  disabled: var(--color-disabled),
  primary: var(--color-primary),
  yin: var(--color-yin),
  yang: var(--color-yang),
  white: var(--color-white),
  black: var(--color-black),
);

$buttonWeight: (
  light: 300,
  normal: 400,
  bold: 600,
);

$buttonSize: (
  xsmall: (
    padding: size(4px) size(2px),
    font-size: size(12px),
  ),
  small: (
    padding: size(5px) 0px,
    font-size: size(13px),
  ),
  medium: (
    padding: size(7px) size(3px),
    font-size: size(14px),
  ),
  large: (
    padding: size(9px) size(5px),
    font-size: size(15px),
  ),
  xlarge: (
    padding: size(10px) size(6px),
    font-size: size(16px),
  ),
);

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  // overflow: hidden;

  .ant-btn {
    display: flex;
    flex-direction: row-reverse !important;
  }

  span {
    color: inherit !important;
    font-weight: inherit !important;
  }

  .button__item * {
    font-weight: 500 !important;
  }

  &--mode {
    &--fill {
      .button__item {
        &--color {
          @each $name, $item in $buttonColor {
            &--#{$name} {
              background-color: $item;
              transition: all 0.3s ease-in-out;

              &:hover {
                opacity: 0.75;
                background-color: $item !important;
              }
            }
          }
        }
      }
    }

    &--stroke {
      .button__item {
        &--color {
          @each $name, $item in $buttonColor {
            &--#{$name} {
              border: 1px solid $item;

              &:hover {
                border: 1px solid $item !important;
              }

              color: $item;
            }
          }
        }
      }
    }

    &--dash {
      .button__item {
        &--color {
          @each $name, $item in $buttonColor {
            &--#{$name} {
              border: 1px dashed $item;

              &:hover {
                border: 1px solid $item !important;
              }

              color: $item;
            }
          }
        }
      }
    }

    &--link {
      .button__item {
        &--color {
          @each $name, $item in $buttonColor {
            &--#{$name} {
              border: none;

              &:hover {
                border: none !important;
              }

              color: $item;
            }
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: initial;
    line-height: 1;
    padding: 0px 10px !important;
    position: relative;

    &__upload {
      position: absolute;
      inset: 0 0 0 0;
      height: 100%;
      width: 100%;

      &__label {
        pointer-events: visible;
        width: 100%;
        height: 100%;
        display: block;

        &__input {
          display: none;
        }
      }
    }

    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;

      &__text,
      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__separa {
        width: 5px;
      }
    }

    &--size {
      @each $name, $item in $buttonSize {
        &--#{$name} {
          .button__item__inner {
            padding: map-get($item, padding);
          }

          .button__item__inner__text {
            font-size: map-get($item, font-size);
          }

          .button__item__inner__icon svg {
            height: calc(map-get($item, font-size) - 0px) !important;
          }
        }
      }
    }

    &--weight {
      @each $name, $item in $buttonWeight {
        &--#{$name} span {
          line-height: 1;
          font-weight: $item;
        }
      }
    }
  }
}
