@import '@gimlite/watermelon/sass/utils/variable.scss';
@import '@gimlite/watermelon/sass/utils/function.scss';

.maps {
  width: 100%;
  height: 100%;
  touch-action: pan-x pan-y;
}

.marker-label {
  &::after {
    content: '';
    position: absolute;
    height: calc(20px);
    width: calc(100% + 10px);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    background-blend-mode: lighten;
  }
}
